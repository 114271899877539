import { AxiosPromise } from 'axios';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Socket, io } from 'socket.io-client';
import useDeepCompareEffect from 'use-deep-compare-effect';

import NotificationProvider from '../providers/NotificationProvider';
import { FirebaseNotifications } from '../service/FirebaseService';
import * as TenantService from '../service/TenantService';
import Alert from '../sweetalert/Alert';
import {
  ActuatorInfo,
  Entidade,
  PagedResponse,
  TEloNotificationService,
  Tenant
} from '../type';
import * as UrlUtils from '../utils/UrlUtils';
import DisabledNotificationProvider from './DisabledNotificationProvider';
import EloNotificationProvider from './EloNotificationProvider';
import { RoleUsuarioDTO, UserProfile } from './KeycloakProvider';

export type SetEntidadeFunction = (entidade: Entidade | undefined) => void;

export type SearchEntidadeFunction = () => AxiosPromise<
  PagedResponse<Entidade>
>;

export type GetVersaoSobreSistemaFunction = () => AxiosPromise<ActuatorInfo>;
export type GetTotalAcessosSobreSistemaFunction = () => AxiosPromise<any>;

export type ApplicationContextType = {
  tenant?: Tenant;
  tenantError?: string;
  entidade?: Entidade;
  onSearchEntidades?: SearchEntidadeFunction;
  onGetVersaoSobreSistema?: GetVersaoSobreSistemaFunction;
  onGetTotalAcessosSobreSistema?: GetTotalAcessosSobreSistemaFunction;
  setEntidade: SetEntidadeFunction;
  userPermissions: RoleUsuarioDTO;
  userProfileStandalone?: UserProfile;
  tenantIdentifier?: string;
  disableNotification?: boolean;
  socket?: Socket;
};

const defaultGetTenantData = (gatewayResource: string) => (
  tenantIdentifier: string
) => TenantService.getTenant(gatewayResource, tenantIdentifier);

const emptyPermission = {
  clientRoles: [],
  realmRoles: []
};

export const ApplicationContext = React.createContext<ApplicationContextType>({
  entidade: undefined,
  onSearchEntidades: undefined,
  onGetVersaoSobreSistema: undefined,
  onGetTotalAcessosSobreSistema: undefined,
  setEntidade: () => {},
  userPermissions: emptyPermission,
  userProfileStandalone: undefined,
  tenantIdentifier: undefined,
  disableNotification: undefined
});

export type GetEntidadePrincipalFunction = () => AxiosPromise<
  Entidade | undefined
>;

export type OnChangeEntidadeFunction = (entidade: Entidade | undefined) => void;

export type ApplicationProviderProps = {
  children: React.ReactNode;
  notificationService?: FirebaseNotifications;
  gatewayResource?: string;
  onChangeEntidade?: OnChangeEntidadeFunction;
  onSearchEntidades?: SearchEntidadeFunction;
  onGetVersaoSobreSistema?: GetVersaoSobreSistemaFunction;
  onGetTotalAcessosSobreSistema?: GetTotalAcessosSobreSistemaFunction;
  onGetEntidadePrincipal?: GetEntidadePrincipalFunction;
  getUserPermission?: (
    entidade: Entidade | undefined
  ) => AxiosPromise<RoleUsuarioDTO>;
  getTenantData?: (tentantIdentifier: string) => AxiosPromise<Tenant>;
  getFirebaseToken?: () => AxiosPromise<string>;
  eloNotificationService?: TEloNotificationService;
  userProfileStandalone?: UserProfile;
  tenantIdentifier?: string;
  disableNotification?: boolean;
};

const authId = '1511ae76';
const socketOpts = {
  path: '/socket_api_socket',
  transports: ['websocket', 'polling'],
  auth: { id: authId }
};
const ApplicationProvider: FC<ApplicationProviderProps> = ({
  children,
  notificationService,
  onSearchEntidades,
  onGetVersaoSobreSistema,
  onGetTotalAcessosSobreSistema,
  onGetEntidadePrincipal,
  onChangeEntidade,
  getUserPermission,
  gatewayResource = '/gateway-api',
  getTenantData,
  getFirebaseToken,
  eloNotificationService,
  userProfileStandalone,
  tenantIdentifier,
  disableNotification
}) => {
  const [entidade, setEntidade] = useState<Entidade | undefined>(undefined);
  const [tenant, setTenant] = useState<Tenant>();
  const [tenantError, setTenantError] = useState<string>();
  const [userPermissions, setUserPermissions] = useState<RoleUsuarioDTO>(
    emptyPermission
  );
  const [websocketUrl, setWebsocketUrl] = useState<string>('');
  const tenantId: string = tenantIdentifier ?? UrlUtils.getTenantIdentifier();

  const getTenant = useMemo(() => {
    return getTenantData ?? defaultGetTenantData(gatewayResource);
  }, [getTenantData, gatewayResource]);

  useEffect(() => {
    getTenant(tenantId)
      .then(response => setTenant(response.data))
      .catch(() =>
        setTenantError(
          `Não foi possível carregar os dados do cliente "${tenantId}".`
        )
      );
  }, []);

  const getWebsocketUrl = eloNotificationService?.getWebsocketURL;
  useEffect(() => {
    if (getWebsocketUrl) {
      getWebsocketUrl().then(response => {
        setWebsocketUrl(response.data);
      });
    }
  }, [getWebsocketUrl]);

  const socketRef = useRef<Socket>();

  useDeepCompareEffect(() => {
    socketRef.current = io(websocketUrl, socketOpts);
    return () => {
      socketRef.current?.close();
    };
  }, [websocketUrl, socketOpts]);

  useEffect(() => {
    onChangeEntidade?.(entidade);
  }, [entidade, onChangeEntidade]);

  useEffect(() => {
    getUserPermission?.(entidade)
      .then(response => setUserPermissions(response.data))
      .catch(error => {
        Alert.error(
          { title: 'Erro ao buscar as permissões do usuário' },
          error
        );
        setUserPermissions(emptyPermission);
      });
  }, [entidade, getUserPermission]);

  useEffect(() => {
    onGetEntidadePrincipal?.().then(response => setEntidade(response.data));
  }, [onGetEntidadePrincipal]);

  return (
    <ApplicationContext.Provider
      value={{
        entidade,
        onSearchEntidades,
        onGetVersaoSobreSistema,
        onGetTotalAcessosSobreSistema,
        setEntidade,
        tenant,
        tenantError,
        userPermissions,
        userProfileStandalone,
        tenantIdentifier,
        disableNotification,
        socket: socketRef.current
      }}
    >
      {notificationService
        ? !disableNotification && (
            <NotificationProvider
              notificationService={notificationService}
              gatewayResource={gatewayResource}
              tenant={tenant}
              getToken={getFirebaseToken}
            >
              {children}
            </NotificationProvider>
          )
        : !disableNotification && (
            <EloNotificationProvider
              tenant={tenant}
              eloNotificationService={eloNotificationService}
              disableNotification={disableNotification}
            >
              {children}
            </EloNotificationProvider>
          )}
      {disableNotification && (
        <DisabledNotificationProvider>{children}</DisabledNotificationProvider>
      )}
    </ApplicationContext.Provider>
  );
};
//    !disableNotification ?
export default ApplicationProvider;
