import { Col, ErrorText, Row } from '@elotech/components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const DadosTransferencia = props => {
  const {
    tipoItbi,
    valorTransacao = 0,
    valorFinanciado = '',
    listTipoItbi,
    onChange,
    onChangeSelect,
    error,
    errorMessages,
    valorRecursoProprio
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col sm={6} className="form-group">
          <label htmlFor="tipoITBIInput">Tipo ITBI</label>
          <select
            data-test-id="tipoITBISelect"
            data-cy="selectTipoITBI"
            id="tipoITBIInput"
            name="tipoItbi"
            className={error.tipoItbi ? 'error' : ''}
            onChange={onChangeSelect}
            value={tipoItbi.value}
          >
            <option value="">Selecione</option>
            {listTipoItbi.map(tipo => (
              <option key={tipo.value} value={tipo.value}>
                {tipo.label}
              </option>
            ))}
          </select>
          {error.tipoItbi && <ErrorText>{errorMessages.tipoItbi}</ErrorText>}
          {error.loadDocumentos && (
            <ErrorText>{errorMessages.loadDocumentos}</ErrorText>
          )}
        </Col>
        <Col sm={3} className="form-group">
          <label htmlFor="valorTransacaoInput">
            {tipoItbi.financiado && valorRecursoProprio
              ? 'Valor Recurso Próprio'
              : 'Valor Transação'}
          </label>
          <NumberFormat
            id="valorTransacaoInput"
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            allowNegative={false}
            value={valorTransacao ?? 0}
            name="valorTransacao"
            data-cy="inputValorTransacao"
            onValueChange={onChange}
            className={classnames({ error: error.valorTransacao, right: true })}
            fixedDecimalScale
          />
          {error.valorTransacao && (
            <ErrorText>{errorMessages.valorTransacao}</ErrorText>
          )}
        </Col>
        {tipoItbi && (tipoItbi.financiado || tipoItbi.anuencia) && (
          <Col sm={3} className="form-group">
            <label htmlFor="valorFinanciadoInput">
              {tipoItbi.financiado ? 'Valor Financiado' : 'Valor Anuência'}
            </label>
            <NumberFormat
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={2}
              allowNegative={false}
              value={valorFinanciado}
              name="valorFinanciado"
              onValueChange={onChange}
              className={classnames({
                error: error.valorFinanciado,
                right: true
              })}
              fixedDecimalScale
            />
            {error.valorFinanciado && (
              <ErrorText>{errorMessages.valorFinanciado}</ErrorText>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={12}>
          {tipoItbi.observacao && (
            <article className="info mb-xs mt-xs" id="no-print">
              {tipoItbi.observacao}
            </article>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

DadosTransferencia.propTypes = {
  tipoItbi: PropTypes.object.isRequired,
  valorTransacao: PropTypes.number,
  valorFinanciado: PropTypes.number,
  listTipoItbi: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  error: PropTypes.object
};

export default DadosTransferencia;
