import {
  ActionButton,
  ActionsGroup,
  Alert,
  ExtraInfo,
  Loading,
  Sort,
  Table,
  formatUtils
} from '@elotech/components';
import { EspelhoCadastroGeralService } from 'itbi-common/service';
import { CadastroGeralCidadaoMobileDTO } from 'itbi-common/type';
import React, { useState } from 'react';

type Props = {
  loading: boolean;
  cadastros: CadastroGeralCidadaoMobileDTO[];
  onSortSearch(sort: Sort | undefined): Promise<void>;
};

const CadastrosList: React.FC<Props> = ({
  cadastros,
  loading,
  onSortSearch
}) => {
  const [loadingImpressao, setLoadingImpressao] = useState<boolean>(false);
  const [currentPrintType, setCurrentPrintType] = useState<
    'cadastro' | 'especifico' | null
  >(null);

  const printEspelho = (
    tipo: 'cadastro' | 'especifico',
    cadastro: CadastroGeralCidadaoMobileDTO
  ) => {
    setLoadingImpressao(true);
    const serviceMethod =
      tipo === 'cadastro'
        ? EspelhoCadastroGeralService.imprimeEspelhoCadastro
        : EspelhoCadastroGeralService.imprimeEspelhoCadastroEspecifico;

    serviceMethod(
      cadastro.tipoCadastro.toString(),
      cadastro.cadastroGeral.toString()
    )
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error(
          {
            title:
              currentPrintType === 'cadastro'
                ? `Erro ao carregar o relatório de Espelho Cadastral`
                : `Erro ao carregar o Espelho Específico`
          },
          error
        );
      })
      .finally(() => setLoadingImpressao(false));
  };

  const onPrintEspelhoCadastral = (cadastro: CadastroGeralCidadaoMobileDTO) => {
    setCurrentPrintType('cadastro');
    printEspelho('cadastro', cadastro);
  };

  const onPrintEspelhoEspecifico = (
    cadastro: CadastroGeralCidadaoMobileDTO
  ) => {
    setCurrentPrintType('especifico');
    printEspelho('especifico', cadastro);
  };

  return (
    <>
      <Loading loading={loadingImpressao} />
      <Table
        keyExtractor={value => `${value.cadastroGeral}-${value.cpfCnpj}`}
        loading={loading}
        values={cadastros}
        sortOnHeaderColumnClick={onSortSearch}
      >
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="Tipo Cadastro"
          sortable
          name="descricaotipocadastro"
          value={value => value.descricaoTipoCadastro}
        />
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="Cadastro Geral"
          sortable
          name="cadastrogeral"
          value={value => value.cadastroGeral}
        />
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="Débitos"
          sortable
          name="situacaodebito"
          value={value => {
            if (value.existeDebito && value.debitoVencido) {
              return (
                <ExtraInfo
                  color={'warning'}
                  value={value.situacaoDebito}
                  letter={'V'}
                />
              );
            }
            if (value.existeDebito && !value.debitoVencido) {
              return (
                <ExtraInfo
                  color={'positive'}
                  value={value.situacaoDebito}
                  letter={'R'}
                />
              );
            }
            if (!value.existeDebito) {
              return (
                <ExtraInfo
                  color={'negative'}
                  value={value.situacaoDebito}
                  letter={'N'}
                />
              );
            }
          }}
        />
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="CPF/CNPJ"
          sortable
          name="cpfcnpj"
          value={value => formatUtils.formatCpfCnpj(value.cpfCnpj)}
        />
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="Nome"
          sortable
          name="nome"
          value={value => value.nome}
        />
        <Table.Column<CadastroGeralCidadaoMobileDTO>
          header="Endereço"
          sortable
          name="endereco"
          value={value => value.endereco}
        />
        <Table.Column
          header=""
          value={(value: CadastroGeralCidadaoMobileDTO) => (
            <ActionsGroup>
              <ActionButton
                key="espelho-button"
                icon="print"
                label="Imprimir Espelho"
                onClick={() => onPrintEspelhoCadastral(value)}
              />
              <ActionButton
                key="espelho-especifico-button"
                icon="print"
                label="Imprimir Espelho Específico"
                onClick={() => onPrintEspelhoEspecifico(value)}
                disabled={value.tipoCadastro !== 1}
              />
              {value.existeDebito && (
                <ActionButton
                  key="extrato-debitos-button"
                  icon="file-invoice-dollar"
                  label="Extrato de Débitos"
                  newTab
                  path={`/propriedades/${value.tipoCadastro}/${value.cadastroGeral}/extrato`}
                />
              )}
            </ActionsGroup>
          )}
        />
      </Table>
    </>
  );
};

export default CadastrosList;
