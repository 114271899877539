import { ActionButton, ActionsGroup } from '@elotech/components';
import React, { FunctionComponent } from 'react';

type CampoDocumentoRowProps = {
  campo: any;
};

type DocumentoRowProps = {
  documento: any;
  onDownload: (documento: any, nomeArquivo: string) => void;
};

type DocumentoRowState = {
  showCampos: boolean;
  showArquivos: boolean;
};

const CampoDocumentoRow: FunctionComponent<CampoDocumentoRowProps> = ({
  campo
}) => (
  <tr>
    <td>{campo.campoDocumento.label}</td>
    <td>{campo.valor}</td>
  </tr>
);

const CampoDocumentoTable: FunctionComponent<{ campos: any[] }> = ({
  campos
}) => {
  return (
    <div className="panel table" style={{ marginBottom: '0px' }}>
      <div className="panel-body table table-responsive">
        <div className="panel-table-outer">
          <table className="panel-table border fancy">
            {campos?.length > 0 && (
              <thead>
                <tr>
                  <th>Campo</th>
                  <th>Valor</th>
                </tr>
              </thead>
            )}
            <tbody>
              {campos.map(campo => (
                <CampoDocumentoRow key={campo.id} campo={campo} />
              ))}
              {campos?.length < 1 && (
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    O documento não possui campos/valores
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ArquivoDocumentoTable: FunctionComponent<{
  documento: any;
  onDownload: (documento: any, nomeArquivo: string) => void;
}> = ({ documento, onDownload }) => {
  return (
    <div className="panel table" style={{ marginBottom: '0px' }}>
      <div className="panel-body table table-responsive">
        <div className="panel-table-outer">
          <table className="panel-table fancy">
            <tbody>
              {documento.nomesArquivos?.map(
                (arquivo: string, index: number) => (
                  <tr>
                    <td>{arquivo}</td>
                    <td>
                      <ActionsGroup>
                        <ActionButton
                          key={`download-button-${index}}`}
                          data-test-id={`download-button-${index}}`}
                          icon="download"
                          label="Download"
                          onClick={() => onDownload(documento, arquivo)}
                        />
                      </ActionsGroup>
                    </td>
                  </tr>
                )
              )}
              {documento.nomesArquivos?.length < 1 && (
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    O documento não possui arquivos para download
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
class DocumentoRow extends React.Component<
  DocumentoRowProps,
  DocumentoRowState
> {
  state = {
    showCampos: false,
    showArquivos: false
  };

  onViewCampos = () => {
    this.setState(state => ({ showCampos: !state.showCampos }));
  };

  onViewArquivos = () => {
    this.setState(state => ({ showArquivos: !state.showArquivos }));
  };

  render() {
    const { documento, onDownload } = this.props;
    const { showCampos, showArquivos } = this.state;

    return (
      <React.Fragment>
        <tr key={documento.id}>
          <td>{documento.documentoItbi.nome}</td>
          <td>
            <ActionsGroup>
              {documento.nomesArquivos && (
                <ActionButton
                  data-test-id={`view-files-button-${documento.id}`}
                  key={`view-files-button-${documento.id}`}
                  icon={showArquivos ? 'chevron-up' : 'chevron-down'}
                  label={
                    showArquivos
                      ? 'Minimizar arquivos do documento'
                      : 'Visualizar arquivos do documento'
                  }
                  onClick={this.onViewArquivos}
                />
              )}
              {documento.campos && (
                <ActionButton
                  data-test-id={`view-fields-button-${documento.id}`}
                  key={`view-fields-button-${documento.id}`}
                  icon={showCampos ? 'chevron-up' : 'chevron-down'}
                  label={
                    showCampos
                      ? 'Minimizar campos do documento'
                      : 'Visualizar campos do documento'
                  }
                  onClick={this.onViewCampos}
                />
              )}
            </ActionsGroup>
          </td>
        </tr>
        {showCampos && (
          <tr style={{ backgroundColor: '#eaf1f9' }}>
            <td colSpan={2}>
              <CampoDocumentoTable campos={documento.campos} />
            </td>
          </tr>
        )}
        {showArquivos && (
          <tr style={{ backgroundColor: '#eaf1f9' }}>
            <td colSpan={2}>
              <ArquivoDocumentoTable
                documento={documento}
                onDownload={onDownload}
              />
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }
}

export default DocumentoRow;
