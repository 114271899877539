import {
  Alert,
  Container,
  FabSpeedDial,
  Icon,
  Info,
  Loading,
  NotificationActions
} from '@elotech/components';
import {
  ContestacaoSectionForm,
  DocumentacaoList,
  DocumentosAvulsosList,
  PessoasQuickView
} from 'itbi-common/components';
import {
  DeclaracaoService,
  ParametroGeralService,
  ParametroService,
  UploadService,
  withService
} from 'itbi-common/service';
import { FunctionUtils } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import DeclaracaoUtils from '../../utils/DeclaracaoUtils';
import { renderFabButtons } from './DeclaracaoActions';
import ResumoDeclaracao from './Resumo';

const CenteredIcon = styled(Icon)`
  margin: 10% 45%;
`;

const moduloTributos = '05';

class DeclaracaoView extends React.Component {
  static propTypes = {
    declaracaoService: PropTypes.shape({
      findDeclaracaoItbiById: PropTypes.func.isRequired,
      imprimirCertidaoQuitacao: PropTypes.func.isRequired,
      copiarDeclaracao: PropTypes.func.isRequired
    }).isRequired,
    showNotification: PropTypes.func.isRequired
  };
  state = {
    declaracaoItbi: undefined,
    loading: false,
    pessoaQuickView: undefined,
    novo: false,
    documentosAvulsos: [],
    termoAceiteArbitramento: false,
    cadastroImobiliarioAtualizado: true,
    cadastroRuralAtualizado: true,
    valorRecursoProprio: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (this.props.history.location.state) {
      this.setState({ novo: true });
    }
    this.onGetDeclaracao(id);
    this.getParametrosItbi();
    this.getParametroRecursoProprio();
  }

  getParametroRecursoProprio = () => {
    this.setState({ loading: true });
    this.props.parametroGeralService
      .findOne(moduloTributos, 'DESCVALORRECURSOPROPRIOITBI')
      .then(response => {
        if (response.data.valor === 'S') {
          this.setState({ valorRecursoProprio: true });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  getParametrosItbi = () => {
    this.setState({ loading: true });
    this.props.parametroService
      .loadAllParametros()
      .then(response => {
        this.setState({
          termoAceiteArbitramento: response.data.termoAceiteArbitramento,
          cadastroImobiliarioAtualizado:
            response.data.cadastroImobiliarioAtualizado,
          cadastroRuralAtualizado: response.data.cadastroRuralAtualizado
        });
      })
      .catch(error =>
        Alert.error({ title: 'Não foi possível carregar os parâmetros' }, error)
      )
      .finally(() => this.setState({ loading: false }));
  };

  validateVizualizacaoContestacaoDeclaracao = async declaracaoItbi => {
    const deveMarcarComoArbitramentoVisualizado =
      !declaracaoItbi.contestacao &&
      declaracaoItbi.situacaoItbi.name === 'AGUARDANDO_ACEITE';

    if (!deveMarcarComoArbitramentoVisualizado) {
      return declaracaoItbi;
    }

    const contestacao = await this.props.declaracaoService.updateDataVizualizacaoContestacao(
      declaracaoItbi.id
    );

    this.setState(prevState => ({
      ...prevState,
      declaracaoItbi: {
        ...prevState.declaracaoItbi,
        contestacao
      }
    }));
  };

  onGetDeclaracaoSuccess = declaracao => {
    const documentosAvulsos = declaracao.documentosAvulsos.filter(
      documentoAvulso => documentoAvulso.visivelCidadao
    );

    this.setState({
      declaracaoItbi: declaracao,
      documentosAvulsos
    });

    return declaracao;
  };

  onGetDeclaracaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar a declaração de ITBI' },
      error
    );
  };

  onGetDeclaracao = id => {
    this.setState({ loading: true });
    this.props.declaracaoService
      .findDeclaracaoItbiById(id)
      .then(({ data }) => data)
      .then(this.onGetDeclaracaoSuccess)
      .then(async declaracao =>
        this.validateVizualizacaoContestacaoDeclaracao(declaracao)
      )
      .catch(this.onGetDeclaracaoError)
      .finally(() => this.setState({ loading: false }));
  };

  onPrint = () => {
    window.print();
  };

  onDownloadArquivoContestacao = urlArquivo => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, urlArquivo)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onDownloadDocumentoAvulso = documento => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;

    return uploadService
      .getUrlDownloadArquivoAvulsoDeclaracao(declaracaoItbi.id, documento.nome)
      .then(urlResult => uploadService.downloadFileS3(urlResult.data.url));
  };

  onDownloadQuitacao = () => {
    DeclaracaoUtils.downloadCertidaoQuitacao(
      this.props.declaracaoService.imprimirCertidaoQuitacao,
      this.state.declaracaoItbi,
      state => this.setState(state),
      this.state.cadastroImobiliarioAtualizado,
      this.state.cadastroRuralAtualizado
    ).then(() => {
      const { id } = this.props.match.params;
      this.onGetDeclaracao(id);
    });
  };

  onCopiar = () => {
    const { id } = this.props.match.params;
    this.props.declaracaoService
      .copiarDeclaracao(id)
      .then(response =>
        this.props.history.push(`/declaracoes-itbi/${response.data.id}`)
      )
      .catch(error =>
        Alert.error(
          { title: 'Não foi possível copiar a declaração de ITBI' },
          error
        )
      );
  };

  onViewPessoa = pessoa => {
    this.setState({
      pessoaQuickView: pessoa
    });
  };

  onCloseQuickViewPessoa = () => {
    this.setState({
      pessoaQuickView: undefined
    });
  };

  onPrintBoleto = () => {
    window.open(this.state.declaracaoItbi.urlBoleto, '_blank');
  };

  onAcceptContestacao = declaracao => {
    this.setState({ loading: true });
    const { termoAceiteArbitramento } = this.state;

    if (termoAceiteArbitramento) {
      this.props.history.push(
        `/declaracoes-itbi/${declaracao.id}/termo-aceite-contestacao`
      );
      return;
    }

    DeclaracaoService.aceitarContestacaoDeclaracao(declaracao.id)
      .then(response => {
        Alert.info({
          title: 'A contestação da declaração de ITBI foi aceita.'
        });
        this.props.history.push(`/declaracoes-itbi/${response.data.id}/resumo`);
      })
      .catch(error =>
        Alert.error(
          {
            title:
              'Não foi possível aceitar a contestação da declaração de ITBI'
          },
          error
        )
      )
      .finally(() => this.setState({ loading: false }));
  };

  onContestContestacao = declaracao => {
    this.props.history.push(`/declaracoes-itbi/${declaracao.id}/contestar`);
  };

  onDownloadDocumentoItbi = (documento, nomeArquivo) => {
    const { uploadService } = this.props;
    const { declaracaoItbi } = this.state;
    uploadService
      .getUrlDownloadS3Declaracao({
        declaracao: declaracaoItbi.id,
        documento: documento.documentoItbi.id,
        arquivo: nomeArquivo
      })
      .then(response => {
        window.open(response.data.url, '_blank');
      });
  };

  onPrintCertidaoIsencao = declaracao => {
    DeclaracaoUtils.printCertidaoIsencao(
      this.props.declaracaoService.printCertidaoIsencao,
      declaracao,
      state => this.setState(state)
    );
  };

  render() {
    const {
      declaracaoItbi,
      loading,
      pessoaQuickView,
      novo,
      documentosAvulsos
    } = this.state;

    if (!declaracaoItbi) {
      return <CenteredIcon size="4x" icon="spinner" spin primary />;
    }

    return (
      <Container title="Resumo da Declaração ITBI" icon="file-signature">
        <Loading loading={loading} />
        {novo ? (
          <Info classes="positive mt-xs no-print">
            {`Declaração gerado com sucesso. O número do pedido é: ${declaracaoItbi.numero}`}
          </Info>
        ) : null}

        <ResumoDeclaracao
          compradores={declaracaoItbi.compradores}
          vendedores={declaracaoItbi.vendedores}
          anuentes={declaracaoItbi.anuentes}
          dadosImovel={declaracaoItbi}
          onViewPessoa={this.onViewPessoa}
          valorRecursoProprio={this.state.valorRecursoProprio}
        />

        <DocumentacaoList
          documentos={declaracaoItbi.documentos}
          onDownload={this.onDownloadDocumentoItbi}
        />

        {documentosAvulsos.length > 0 ? (
          <DocumentosAvulsosList
            documentos={documentosAvulsos}
            onDownload={this.onDownloadDocumentoAvulso}
            filter={doc => !doc.arbitragem && doc.visivelCidadao}
          />
        ) : null}

        {documentosAvulsos.length &&
        documentosAvulsos.some(e => e.arbitragem) ? (
          <DocumentosAvulsosList
            documentos={documentosAvulsos}
            onDownload={this.onDownloadDocumentoAvulso}
            customSectionTitle={'Documentos arbitragem'}
            filter={doc => doc.arbitragem && doc.visivelCidadao}
          />
        ) : null}

        <PessoasQuickView
          pessoa={pessoaQuickView}
          onClose={this.onCloseQuickViewPessoa}
        />

        {declaracaoItbi?.contestacao && (
          <ContestacaoSectionForm
            contestacao={declaracaoItbi.contestacao}
            onDownload={this.onDownloadArquivoContestacao}
            isVisibleAllInformations={true}
          />
        )}

        <FabSpeedDial icon="ellipsis-v" title="Ações">
          {renderFabButtons(declaracaoItbi, {
            onPrintBoleto: this.onPrintBoleto,
            onDownloadQuitacao: this.onDownloadQuitacao,
            onCopy: this.onCopiar,
            onPrint: this.onPrint,
            onContest: this.onContestContestacao,
            onAccept: this.onAcceptContestacao,
            onPrintCertidaoIsencao: this.onPrintCertidaoIsencao
          })}
        </FabSpeedDial>
      </Container>
    );
  }
}

const enhancers = FunctionUtils.compose(
  withService({
    declaracaoService: DeclaracaoService,
    uploadService: UploadService,
    parametroService: ParametroService,
    parametroGeralService: ParametroGeralService
  }),
  connect(null, {
    showNotification: NotificationActions.showNotification
  })
);

const enhancedComponent = enhancers(DeclaracaoView);

export { enhancedComponent as default, DeclaracaoView };
