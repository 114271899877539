import { SectionTitle } from '@elotech/components';
import React from 'react';

import DocumentoRow from './DocumentoRow';

type Props = {
  title?: string;
  documentos: any[];
  onDownload: (documento: any, nomeArquivo: string) => void;
};
const DocumentacaoList: React.FunctionComponent<Props> = ({
  title,
  documentos,
  onDownload
}) => {
  if (documentos.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <SectionTitle>{title || 'Documentações'}</SectionTitle>
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer" style={{ overflow: 'visible' }}>
            <table className="panel-table striped fancy">
              <tbody>
                {documentos.map(documento => (
                  <DocumentoRow
                    key={documento.id}
                    documento={documento}
                    onDownload={onDownload}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentacaoList;
