import { KeycloakService } from '@elotech/components';

import * as AvaliacaoService from './AvaliacaoService';
import * as BairroAiseService from './BairroAiseService';
import * as BloquetoService from './BloquetoService';
import * as BugsnagService from './BugsnagService';
import * as CadastroGeralService from './CadastroGeralService';
import * as CadastroRuralService from './CadastroRuralService';
import * as CepService from './CepService';
import * as CertidaoService from './CertidaoService';
import * as CidadeService from './CidadeService';
import * as ConcursoPremiadoService from './ConcursoPremiadoService';
import * as ConsultaCarneService from './ConsultaCarneService';
import * as DebitoService from './DebitoService';
import * as DeclaracaoService from './DeclaracaoService';
import * as DocumentoGeradoService from './DocumentoGeradoService';
import * as DocumentoItbiService from './DocumentoItbiService';
import * as DocumentoTipoService from './DocumentoTipoService';
import * as EmailService from './EmailService';
import * as EnderecoAiseService from './EnderecoAiseService';
import * as EntidadeService from './EntidadeService';
import * as EspelhoCadastroGeralService from './EspelhoCadastroGeralService';
import * as ExtratoDebitoService from './ExtratoDebitoService';
import * as FaleConoscoService from './FaleConoscoService';
import * as FaqService from './FaqService';
import * as FinalidadeService from './FinalidadeService';
import * as ImobiliarioService from './ImobiliarioService';
import * as LaudoService from './LaudoService';
import * as MensagemCidadaoService from './MensagemCidadaoService';
import * as MenuItbiService from './MenuItbiService';
import * as MudancaMonetariaService from './MudancaMonetariaService';
import * as ParametroGeralService from './ParametroGeralService';
import * as ParametroItbiService from './ParametroItbiService';
import * as ParametroService from './ParametroService';
import * as ParamParcelamentoService from './ParamParcelamentoService';
import * as ParcelamentoService from './ParcelamentoService';
import * as PedidoService from './PedidoService';
import * as PrintService from './PrintService';
import * as ProcessoService from './ProcessoService';
import * as PropriedadeService from './PropriedadeService';
import * as ProprietarioService from './ProprietarioService';
import * as ReportarProblemaService from './ReportarProblemaService';
import * as SegmentoService from './SegmentoService';
import * as SincronizacaoDadosService from './SincronizacaoDadosService';
import * as TermoResponsabilidadeService from './TermoResponsabilidadeService';
import * as TipoCadastroService from './TipoCadastroService';
import * as TipoItbiService from './TipoItbiService';
import * as TramiteService from './TramiteService';
import * as UploadService from './UploadService';
import * as UserService from './UserService';
import * as UsuarioExternoService from './UsuarioExternoService';
import * as VerificacaoService from './VerificacaoService';
import withService from './withService';

export {
  AvaliacaoService,
  BairroAiseService,
  BloquetoService,
  BugsnagService,
  CadastroGeralService,
  CadastroRuralService,
  CepService,
  CertidaoService,
  CidadeService,
  ConcursoPremiadoService,
  ConsultaCarneService,
  DebitoService,
  DeclaracaoService,
  DocumentoItbiService,
  DocumentoGeradoService,
  DocumentoTipoService,
  EmailService,
  EnderecoAiseService,
  EntidadeService,
  EspelhoCadastroGeralService,
  ExtratoDebitoService,
  FaqService,
  FaleConoscoService,
  FinalidadeService,
  ImobiliarioService,
  LaudoService,
  KeycloakService,
  MensagemCidadaoService,
  MudancaMonetariaService,
  ParametroGeralService,
  ParametroItbiService,
  ParametroService,
  ParcelamentoService,
  ParamParcelamentoService,
  PedidoService,
  PrintService,
  ProcessoService,
  PropriedadeService,
  ProprietarioService,
  ReportarProblemaService,
  SegmentoService,
  SincronizacaoDadosService,
  TermoResponsabilidadeService,
  TipoCadastroService,
  TipoItbiService,
  TramiteService,
  MenuItbiService,
  UploadService,
  UserService,
  UsuarioExternoService,
  VerificacaoService,
  withService
};
