import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import React, { forwardRef } from 'react';

import { ptBr } from './Pt-br';

type Props = {
  events: any[];
  onClickEvent?: () => void;
  initialDate?: string;
  handleChangeMonth?: () => void;
  initialView?: string;
};

const EventCalendar = forwardRef<FullCalendar, Props>(
  (
    {
      events,
      onClickEvent,
      initialDate,
      handleChangeMonth,
      initialView
    }: Props,
    ref
  ) => {
    return (
      <FullCalendar
        initialView={initialView || 'dayGridMonth'}
        events={events}
        plugins={[dayGridPlugin, interactionPlugin]}
        locale={ptBr}
        eventClick={onClickEvent}
        ref={ref}
        initialDate={initialDate}
        datesSet={handleChangeMonth}
      />
    );
  }
);

export default EventCalendar;
