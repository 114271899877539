import React from 'react';

const ErrorText = ({ children, style = undefined }) => {
  return (
    <p className="help-message" style={style}>
      {children}
    </p>
  );
};

export default ErrorText;
