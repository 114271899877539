import {
  Checkbox,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  FormattedDate,
  Panel,
  Row,
  SectionTitle,
  Table
} from '@elotech/components';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {
  DebitoEmAberto,
  DebitoParcelamentoTotalizador,
  DebitoPassivelParcelamentoDTO,
  ParametroParcelamento,
  ParcelamentoCadastroDTO,
  SimulacaoDTO
} from '../../../types';
import { Requerente } from './ParcelamentoFormPage';
import ParcelamentoIdentificacao from './ParcelamentoIdentificacao';

type Props = {
  requerente: Requerente;
  cadastro: ParcelamentoCadastroDTO;
  leiSelecionada: ParametroParcelamento;
  simulacaoSelecionada: SimulacaoDTO;
  debitosSelecionados: DebitoEmAberto[];
  onCheckTermoConfissao(event: React.ChangeEvent<HTMLInputElement>): void;
  checkedTermoConfissaoDivida: boolean;
  totalizador: DebitoParcelamentoTotalizador;
  concedeDescontoNoPagamento: boolean;
  debitosPassiveisParcelamento: Record<string, DebitoPassivelParcelamentoDTO[]>;
};

const ConteudoTermo = styled.div`
  border: 1px solid #e0e0e0;
  text-align: justify;
  max-height: 200px;
  overflow-y: scroll;
  margin: 0 20px;
  padding: 5px 10px;
  white-space: pre-line;
`;

const verifyScrollEnd = (element: HTMLDivElement): boolean => {
  return (
    Math.ceil(element.scrollHeight) -
      Math.ceil(element.scrollTop) -
      element.clientHeight <=
    5
  );
};

const ResumoStep: React.FC<Props> = ({
  cadastro,
  requerente,
  leiSelecionada,
  simulacaoSelecionada,
  totalizador,
  debitosSelecionados,
  onCheckTermoConfissao,
  checkedTermoConfissaoDivida,
  concedeDescontoNoPagamento,
  debitosPassiveisParcelamento
}) => {
  const [scrollEnd, setScrollEnd] = useState<boolean>(false);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  //Precisou dar esse cast pois o ref do styled-components tem um problema e nao está compatível com o ref do react
  const refTermo = useRef() as React.MutableRefObject<HTMLDivElement | null>;

  useEffect(() => {
    if (refTermo.current) {
      const isEnded = verifyScrollEnd(refTermo.current);
      setScrollEnd(isEnded);
      if (isEnded) {
        setCheckboxChecked(true);
        onCheckTermoConfissao({
          target: { name: 'checkedTermoConfissaoDivida', checked: true }
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  }, [refTermo, onCheckTermoConfissao]);

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!scrollEnd) {
      setScrollEnd(verifyScrollEnd(event.currentTarget));
    }
    if (verifyScrollEnd(event.currentTarget) && !scrollEnd) {
      setScrollEnd(true);
      setCheckboxChecked(true);
      onCheckTermoConfissao({
        target: { name: 'checkedTermoConfissaoDivida', checked: true }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(event.target.checked);
    onCheckTermoConfissao(event);
  };

  const getValorDebitoSelecionado = (iddebito: number) =>
    debitosPassiveisParcelamento[leiSelecionada.id]
      .filter(debito => debito.iddebito === iddebito)
      .reduce((result, current) => result + current.valordebito, 0) ?? 0;

  const getValorAVistaDebitoSelecionado = (iddebito: number) =>
    simulacaoSelecionada.debitosParcelamento
      .filter(debito => debito.idDebito === iddebito)
      .reduce((result, current) => result + current.valorAVista, 0) ?? 0;

  return (
    <>
      <ParcelamentoIdentificacao
        cadastro={cadastro}
        requerente={requerente}
        lei={leiSelecionada}
        totalizador={totalizador}
      />
      <SectionTitle>Débitos</SectionTitle>
      <DisplayDataGrid highlight large>
        <Row>
          <DisplayDataItem title="Lei" md={4}>
            {leiSelecionada.descricao}
          </DisplayDataItem>
          <DisplayDataItem title="Parcelas" md={4}>
            {`${simulacaoSelecionada!.numeroParcelas} x `}
            <FormattedCurrency
              value={
                simulacaoSelecionada!.valorDemaisParcelas ||
                simulacaoSelecionada!.valorEntrada
              }
            />
          </DisplayDataItem>
          <DisplayDataItem title="Total" md={4}>
            <FormattedCurrency value={simulacaoSelecionada.valorTotal} />
          </DisplayDataItem>
          {concedeDescontoNoPagamento ? (
            <DisplayDataItem title="Total no Vencimento" md={4}>
              <FormattedCurrency
                value={simulacaoSelecionada.valorTotalAVista}
              />
            </DisplayDataItem>
          ) : (
            undefined
          )}
        </Row>
      </DisplayDataGrid>
      <Panel isTable className="mt-xs">
        <Table
          values={debitosSelecionados}
          fixed
          keyExtractor={item => item.idDebito}
        >
          <Table.Column<DebitoEmAberto>
            header="Ano"
            value={item => item.exercicio}
          />
          <Table.Column<DebitoEmAberto>
            header="Dívida"
            value={item => `${item.divida} - ${item.descricaoDivida}`}
          />
          <Table.Column<DebitoEmAberto>
            header="Subdívida"
            value={item => item.subDivida}
          />
          <Table.Column<DebitoEmAberto>
            header="Vencimento"
            value={item => <FormattedDate value={item.dataVencimento} />}
          />
          <Table.Column<DebitoEmAberto>
            header="Situação Legal"
            value={item => item.situacao}
          />
          <Table.Column<DebitoEmAberto>
            header="Valor"
            headerClassName="right"
            className="right"
            value={item => (
              <FormattedCurrency
                value={
                  !concedeDescontoNoPagamento
                    ? item.valorTotal
                    : getValorDebitoSelecionado(item.idDebito)
                }
              />
            )}
          />
          {concedeDescontoNoPagamento ? (
            <Table.Column<DebitoEmAberto>
              header="Valor no Vencimento"
              headerClassName="right"
              className="right"
              value={item => (
                <FormattedCurrency
                  value={getValorAVistaDebitoSelecionado(item.idDebito)}
                />
              )}
            />
          ) : (
            undefined
          )}
        </Table>
      </Panel>
      <SectionTitle>
        Minuta de Contrato e Termo de Confissão de Dívidas
      </SectionTitle>
      <ConteudoTermo ref={refTermo} onScroll={onScroll}>
        {leiSelecionada.termoConfissaoDivida}
      </ConteudoTermo>
      {!scrollEnd && (
        <p
          className="help-message ml-xs"
          style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        >
          Leia o termo até o fim para poder aceitá-lo
        </p>
      )}
      <Checkbox
        id="term-agreement"
        name="termoConfissaoDivida"
        checked={checkboxChecked}
        onChange={onCheckboxChange}
        label="Li e aceito o Termo de Confissão de Dívida"
        className="form-group"
        disabled={!scrollEnd}
      />
    </>
  );
};

export default ResumoStep;
