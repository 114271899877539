import { useContext } from 'react';
import { Socket } from 'socket.io-client';

import { ApplicationContext } from '../providers/ApplicationProvider';

const useSocket = (): Socket | undefined => {
  const { socket } = useContext(ApplicationContext);

  return socket;
};

export { useSocket };
