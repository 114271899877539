import {
  Button,
  Checkbox,
  Chip,
  Container,
  ErrorText,
  FAB,
  InputDate,
  Loading,
  Masks,
  RadioButton,
  Search,
  SectionTitle
} from '@elotech/components';
import { EnderecoForm } from 'itbi-common/components';
import { Constantes } from 'itbi-common/utils';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const verifyScrollEnd = element => {
  return element.scrollHeight - element.scrollTop === element.clientHeight;
};

const ConteudoTermo = styled.div`
  border: 1px solid #e0e0e0;
  text-align: justify;
  max-height: 200px;
  overflow-y: scroll;
  margin: 0 20px;
  padding: 5px 10px;
  white-space: pre-line;
`;
const tipoContaValues = [
  {
    key: 'PESSOAFISICA',
    value: 'FISICA',
    label: 'Pessoa Física'
  },
  {
    key: 'PESSOAJURIDICA',
    value: 'JURIDICA',
    label: 'Pessoa Jurídica'
  }
];

const labels = {
  FISICA: {
    dataLabel: 'Dados Pessoais',
    cpfCnpjLabel: 'CPF',
    nameLabel: 'Nome Completo'
  },
  JURIDICA: {
    dataLabel: 'Dados Empresariais',
    cpfCnpjLabel: 'CNPJ',
    nameLabel: 'Razão Social',
    alternateNameLabel: 'Nome Fantasia'
  }
};

const errorMessages = {
  'cpfCnpj-FISICA': 'O CPF é obrigatório.',
  'cpfCnpj-JURIDICA': 'O CNPJ é obrigatório.',
  'cpfCnpj-invalido-FISICA': 'CPF inválido.',
  'cpfCnpj-invalido-JURIDICA': 'CNPJ inválido.',
  'nome-FISICA': 'O nome é obrigatório.',
  'nome-JURIDICA': 'A razão social é obrigatória.',
  nomeFantasia: 'O nome fantasia é obrigatório.',
  email: 'O email é obrigatório.',
  termoResponsabilidade: 'O termo de responsabilidade é obrigatório.',
  telefoneObrigatorio: 'O telefone é obrigatório.',
  telefoneInvalido: 'Telefone inválido.',
  tipoProcurador: 'O tipo procurador é obrigatório.'
};

const Privacy = props => {
  const { onResetPassword } = props;
  return (
    <div>
      <SectionTitle>Privacidade e Segurança</SectionTitle>
      <Button type={'button'} onClick={onResetPassword}>
        REDEFINIR A SENHA
      </Button>
    </div>
  );
};

const LevelUp = props => {
  const {
    onLevelUp,
    userNivel,
    showLevelUpForm,
    canLevelUp,
    toggleLevelUpForm
  } = props;

  const [codigoValidador, setCodigoValidador] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const onChangeField = event => setCodigoValidador(event.target.value);

  const onSubmit = () => {
    if (!codigoValidador.trim()) return setErrorMessage(true);

    setErrorMessage(false);
    onLevelUp(codigoValidador);
  };

  return (
    <>
      <SectionTitle hasButton={canLevelUp}>
        Níveis de Acesso
        {canLevelUp && !showLevelUpForm && (
          <Button onClick={toggleLevelUpForm}>ELEVAR NÍVEL</Button>
        )}
      </SectionTitle>

      {showLevelUpForm && (
        <FormGroup>
          <Row>
            <Col md={3}>
              <label htmlFor="nameInput" className="label">
                Código Validador
              </label>
              <input
                id="nameInput"
                type="text"
                value={codigoValidador}
                name="codigoValidador"
                onChange={onChangeField}
              />
              {errorMessage && (
                <ErrorText>O Código Validador é obrigatório.</ErrorText>
              )}
            </Col>
            <Col md={3} className={'mt-xs'}>
              <Button onClick={onSubmit}>ELEVAR NÍVEL</Button>
            </Col>
          </Row>
        </FormGroup>
      )}

      {!showLevelUpForm && userNivel?.length > 0 && (
        <Row>
          <Col md={12}>
            {userNivel &&
              userNivel.map(({ descricao, nivel }) => (
                <Chip
                  inline
                  key={nivel}
                  value={descricao}
                  color="module-color"
                />
              ))}
          </Col>
        </Row>
      )}

      {!showLevelUpForm && !userNivel?.length && (
        <Row>
          <Col md={12}>
            <p>Nenhum nível de acesso registrado.</p>
          </Col>
        </Row>
      )}
    </>
  );
};

const AuthorizedUsers = props => {
  const {
    currentUser,
    authorizedUsers,
    searchText,
    onChangeSearch,
    onClear,
    loading,
    notFound,
    onAdd,
    onRemove,
    viewHistoryAuthorization
  } = props;
  return (
    <div>
      <SectionTitle hasButton={true}>
        Usuários Autorizados{' '}
        <button
          data-test-id="buttonNovo"
          className="btn module-color"
          onClick={viewHistoryAuthorization}
        >
          Histórico de Autorizações
        </button>
      </SectionTitle>

      <div className="outer-div row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="label" htmlFor="authorized-user-search">
              Usuário
            </label>
            <Search
              type="text"
              name="authorized-user-search"
              onChange={onChangeSearch}
              value={searchText}
              maxLength={11}
              pattern="[0-9]*"
              loading={loading}
              placeholder="Pesquise por CPF"
              selected={!!currentUser}
              onClear={onClear}
              autoComplete="off"
            />

            {notFound && (
              <ErrorText className="help-message">
                Não foi possível encontrar nenhum usuário com o CPF informado.
              </ErrorText>
            )}
          </div>
        </div>

        {currentUser && (
          <React.Fragment>
            <div className="col-md-3">
              <div className="form-group">
                <label className="label">Nome</label>
                <label>{currentUser.nome}</label>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label className="label" />
                <button
                  type="button"
                  onClick={onAdd}
                  className="btn module-color"
                >
                  Adicionar
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      <div>
        {authorizedUsers.length > 0 && (
          <div className="table-editable">
            <div className="table-editable-header hidden-xs">
              <div className="row">
                <div className="col-md-2">CPF</div>
                <div className="col-md-9">Nome</div>
                <div className="col-md-1" />
              </div>
            </div>
            <div className="table-editable-content">
              {authorizedUsers.map((user, index) => (
                <div className="row" key={user.id}>
                  <div className="col-md-2 column" data-header="CPF">
                    {user.cpfCnpj}
                  </div>
                  <div className="col-md-9 column" data-header="Nome">
                    {user.nome}
                  </div>
                  <div className="col-md-1 column btn-actions">
                    <button onClick={onRemove(index)} className="btn">
                      <i className="fa fa-trash" />
                      <div className="btn-actions-label"> Excluir </div>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Term = props => {
  const { error, onChangeField, termoResponsabilidade, mostraCheckbox } = props;

  const [scrollEnd, setScrollEnd] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const primeiroEvento = useCallback(() => {
    const checkbox = document.querySelector('#term-agreement');
    if (checkbox !== null && checkbox !== undefined) {
      checkbox.checked = true;
      setCheckboxChecked(true);
      onChangeField({
        target: { name: 'termoResponsabilidade', value: true }
      });
    }
  }, [onChangeField]);

  const refTermo = useCallback(
    node => {
      if (node !== null) {
        const isEnded = verifyScrollEnd(node);
        setScrollEnd(isEnded);
        if (isEnded) {
          primeiroEvento();
        }
      }
    },
    [primeiroEvento]
  );

  const onScroll = event => {
    if (!scrollEnd) {
      setScrollEnd(verifyScrollEnd(event.currentTarget));
    }
    if (verifyScrollEnd(event.currentTarget) && !scrollEnd) {
      setScrollEnd(true);
      primeiroEvento();
    }
  };

  const onCheckboxChange = event => {
    setCheckboxChecked(event.target.checked);
    onChangeField(event);
  };

  return (
    <div>
      <SectionTitle>Termo de Responsabilidade</SectionTitle>

      {!!termoResponsabilidade?.conteudo ? (
        <ConteudoTermo
          data-testid="conteudo-termo"
          ref={refTermo}
          onScroll={onScroll}
        >
          {termoResponsabilidade.conteudo}
        </ConteudoTermo>
      ) : null}
      {!scrollEnd && mostraCheckbox && (
        <p className="help-message ml-xs" style={{ fontSize: '14px' }}>
          Leia o termo até o fim para poder aceitá-lo
        </p>
      )}
      {mostraCheckbox ? (
        <Checkbox
          id="term-agreement"
          name="termoResponsabilidade"
          checked={checkboxChecked}
          onChange={onCheckboxChange}
          label={'Li e aceito o Termo de Responsabilidade'}
          className="form-group"
          disabled={!scrollEnd}
        />
      ) : null}

      {error.termoResponsabilidade && (
        <p className="help-message ml-xs" style={{ fontSize: '14px' }}>
          {errorMessages.termoResponsabilidade}
        </p>
      )}
    </div>
  );
};

const MyAccountForm = props => {
  const {
    user,
    userExists,
    userNivel,
    desabilitaEdicao,
    error,
    onChangeField,
    onChangeFieldMask,
    onSave,
    onResetPassword,
    onAddAuthorizedUser,
    onChangeAuthorizedUserSearchText,
    onClearAuthorizedUser,
    onRemoveAuthorizedUser,
    userNotFound,
    authorizedUserSearchLoading,
    currentAuthorizedUser,
    authorizedUserSearchText,
    authorizedUsers,
    termoResponsabilidade,
    loadingSave,
    viewHistoryAuthorization,
    onChangeProcurador,
    onLevelUp,
    canLevelUp,
    showLevelUpForm,
    toggleLevelUpForm
  } = props;

  const {
    cpfCnpj,
    nome,
    nomeFantasia,
    dataNascimento,
    email,
    emailAlternativo,
    telefone,
    tipoPessoa,
    procurador,
    tipoProcurador,
    aceitouUltimoTermo
  } = user;

  return (
    <Container title="Minha Conta">
      <Loading loading={loadingSave} />
      <SectionTitle marginTop="0px">
        {labels[tipoPessoa].dataLabel}
      </SectionTitle>
      <div className="row">
        <div className="form-group col-md-12">
          <RadioButton
            onChange={onChangeField}
            label="Tipo Conta"
            options={tipoContaValues}
            selectedValue={tipoPessoa}
            name="tipoPessoa"
            disabled={desabilitaEdicao}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-2">
          <label htmlFor="cpfCnpjInput" className="label">
            {labels[tipoPessoa].cpfCnpjLabel}
          </label>
          <MaskedInput
            id="cpfCnpjInput"
            type="text"
            name="cpfCnpj"
            value={cpfCnpj}
            onChange={onChangeFieldMask}
            mask={tipoPessoa === 'FISICA' ? Masks.MASK_CPF : Masks.MASK_CNPJ}
            showMask={Boolean(cpfCnpj)}
            disabled={desabilitaEdicao}
            className={error.cpfCnpj || error.cpfCnpjInvalido ? 'error' : ''}
            maxLength={tipoPessoa === 'FISICA' ? 15 : 19}
          />
          {error.cpfCnpj && (
            <ErrorText>{errorMessages[`cpfCnpj-${tipoPessoa}`]}</ErrorText>
          )}
          {error.cpfCnpjInvalido && (
            <ErrorText>
              {errorMessages[`cpfCnpj-invalido-${tipoPessoa}`]}
            </ErrorText>
          )}
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="nameInput" className="label">
            {labels[tipoPessoa].nameLabel}
          </label>
          <input
            id="nameInput"
            type="text"
            value={nome}
            name="nome"
            onChange={onChangeField}
            className={error.nome ? 'error' : ''}
          />
          {error.nome && (
            <ErrorText>{errorMessages[`nome-${tipoPessoa}`]}</ErrorText>
          )}
        </div>

        {tipoPessoa === 'JURIDICA' && (
          <div className="form-group col-md-3">
            <label htmlFor="alternateNameInput" className="label">
              {labels[tipoPessoa].alternateNameLabel}
            </label>
            <input
              id="alternateNameInput"
              type="text"
              name="nomeFantasia"
              value={nomeFantasia}
              onChange={onChangeField}
              className={error.nomeFantasia ? 'error' : ''}
            />
            {error.nomeFantasia && (
              <ErrorText>{errorMessages.nomeFantasia}</ErrorText>
            )}
          </div>
        )}
        {tipoPessoa === 'FISICA' && (
          <div className="form-group col-md-2">
            <label htmlFor="dataNascimentoInput" className="label">
              Data de Nascimento <span className="optional">Opcional</span>
            </label>
            <InputDate
              alt="Data de Nascimento"
              id="dataNascimentoInput"
              value={dataNascimento}
              name="dataNascimento"
              onChange={onChangeField}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="emailInput" className="label">
            E-mail
          </label>
          <input
            id="emailInput"
            type="text"
            value={email}
            name="email"
            onChange={onChangeField}
            className={error.email ? 'error' : ''}
          />
          {error.email && <ErrorText>{errorMessages.email}</ErrorText>}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="emailAlternativoInput" className="label">
            E-mail alternativo<span className="optional">Opcional</span>
          </label>
          <input
            alt="E-mail alternativo"
            id="emailAlternativoInput"
            type="text"
            value={emailAlternativo}
            name="emailAlternativo"
            onChange={onChangeField}
          />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="telefoneInput" className="label">
            Telefone
          </label>
          <MaskedInput
            id="telefoneInput"
            type="text"
            value={telefone ?? ''}
            name="telefone"
            onChange={onChangeFieldMask}
            mask={Masks.MASK_TELEFONE}
            showMask={Boolean(telefone)}
            className={
              error.telefoneObrigatorio || error.telefoneInvalido ? 'error' : ''
            }
          />
          {(error.telefoneObrigatorio || error.telefoneInvalido) && (
            <ErrorText>
              {error.telefoneObrigatorio
                ? errorMessages.telefoneObrigatorio
                : errorMessages.telefoneInvalido}
            </ErrorText>
          )}
        </div>
      </div>
      <EnderecoForm
        endereco={user}
        errors={error}
        onChangeField={onChangeField}
        onChangeFieldMask={onChangeFieldMask}
      />
      {tipoPessoa === 'JURIDICA' && (
        <>
          <div className="row">
            <div className="form-group col-md-3">
              <Checkbox
                id="procurador"
                onChange={onChangeProcurador}
                label="Declaro que sou procurador"
                name="procurador"
                checked={procurador}
              />
            </div>
          </div>
          <div className="row">
            {procurador && (
              <div className="form-group col-md-3">
                <label htmlFor="tipoProcurador" className="label">
                  Tipo de Procurador
                </label>
                <select
                  id="tipoProcurador"
                  className={error.tipoProcurador ? 'error' : ''}
                  name="tipoProcurador"
                  value={tipoProcurador}
                  onChange={onChangeField}
                >
                  <option value="">Selecione</option>
                  {Constantes.optionsTipoProcurador.map(item => (
                    <option key={item.key} value={item.key}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {error.tipoProcurador && (
                  <ErrorText>{errorMessages.tipoProcurador}</ErrorText>
                )}
              </div>
            )}
          </div>
        </>
      )}

      <LevelUp
        onLevelUp={onLevelUp}
        userNivel={userNivel}
        showLevelUpForm={showLevelUpForm}
        canLevelUp={canLevelUp}
        toggleLevelUpForm={toggleLevelUpForm}
      />

      {userExists && tipoPessoa === 'JURIDICA' && (
        <AuthorizedUsers
          onAdd={onAddAuthorizedUser}
          onChangeSearch={onChangeAuthorizedUserSearchText}
          onClear={onClearAuthorizedUser}
          onRemove={onRemoveAuthorizedUser}
          notFound={userNotFound}
          loading={authorizedUserSearchLoading}
          authorizedUsers={authorizedUsers}
          currentUser={currentAuthorizedUser}
          searchText={authorizedUserSearchText}
          viewHistoryAuthorization={viewHistoryAuthorization}
        />
      )}
      {userExists && <Privacy onResetPassword={onResetPassword} />}
      <Term
        onChangeField={onChangeField}
        termoAceito={user.termoResponsabilidade}
        error={error}
        termoResponsabilidade={termoResponsabilidade}
        mostraCheckbox={!userExists || !aceitouUltimoTermo}
      />

      <div className="btn-save">
        <FAB icon="check" iconColor="white" title="Salvar" onClick={onSave} />
      </div>
    </Container>
  );
};

AuthorizedUsers.propTypes = {
  searchText: PropTypes.string,
  currentUser: PropTypes.object,
  authorizedUsers: PropTypes.array,
  loading: PropTypes.bool,
  notFound: PropTypes.bool,
  onChangeSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

Privacy.propTypes = {
  onResetPassword: PropTypes.func.isRequired
};

Term.propTypes = {
  termoAceito: PropTypes.bool,
  termoResponsabilidade: PropTypes.object,
  error: PropTypes.object,
  onChangeField: PropTypes.func.isRequired,
  mostraCheckbox: PropTypes.bool
};

MyAccountForm.propTypes = {
  authorizedUserSearchText: PropTypes.string,
  currentAuthorizedUser: PropTypes.object,
  authorizedUsers: PropTypes.array,
  termoResponsabilidade: PropTypes.object,
  user: PropTypes.object,
  error: PropTypes.object,
  userExists: PropTypes.bool,
  userNotFound: PropTypes.bool,
  authorizedUserSearchLoading: PropTypes.bool,
  onResetPassword: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onChangeFieldMask: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onAddAuthorizedUser: PropTypes.func.isRequired,
  onChangeAuthorizedUserSearchText: PropTypes.func.isRequired,
  onClearAuthorizedUser: PropTypes.func.isRequired,
  onRemoveAuthorizedUser: PropTypes.func.isRequired,
  desabilitaCpfCnpj: PropTypes.bool,
  onChangeProcurador: PropTypes.func.isRequired
};

export default MyAccountForm;
