import { Alert } from '@elotech/components';

const DeclaracaoUtils = {
  downloadCertidaoQuitacao: (
    serviceFunction,
    declaracao,
    setState,
    usaCadastroImobiliarioAtualizado,
    usaCadastroRuralAtualizado
  ) => {
    setState({ loading: true });
    const cadastroAtualizado =
      declaracao.tipoImovel === 'RURAL'
        ? usaCadastroRuralAtualizado
        : usaCadastroImobiliarioAtualizado;
    const urlPromise =
      cadastroAtualizado || !declaracao.urlCertidaoQuitacao
        ? serviceFunction(declaracao.id).then(
            response => response.data.urlCertidaoQuitacao
          )
        : Promise.resolve(declaracao.urlCertidaoQuitacao);

    return new Promise((resolve, reject) => {
      urlPromise
        .then(url => {
          window.open(url, '_blank');
          setState({ loading: false });
          resolve();
        })
        .catch(error => {
          setState({ loading: false });
          Alert.error(
            { title: 'Não foi possível imprimir a declaração de quitação' },
            error
          );
          reject(error);
        });
    });
  },
  printCertidaoIsencao: (serviceFunction, declaracao, setState) => {
    setState({ loading: true });

    serviceFunction(declaracao.id)
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window && window.open(fileURL, '_blank');
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível imprimir a declaração de isenção' },
          error
        );
      })
      .finally(() => setState({ loading: false }));
  },
  extractUid: url => {
    const urlParts = url.split('?');
    if (urlParts.length !== 2) {
      return '';
    }
    const queryParams = urlParts[1].split('&');
    const uidParam = queryParams.find(param => param.startsWith('uid='));
    if (!uidParam) {
      return '';
    }
    return uidParam.split('=')[1];
  },
  isUidExpired: uid => {
    if (!uid || uid === '') {
      return true;
    }
    const uidParts = uid.split('.');
    if (uidParts.length !== 3) {
      return true;
    }
    const uidDecoded = JSON.parse(atob(uidParts[1]));
    if (!uidDecoded?.exp) {
      return true;
    }
    const expirationDate = new Date(uidDecoded.exp * 1000);
    return expirationDate < new Date();
  },
  imprimirDocumentoIsencao: (
    serviceFunction,
    declaracao,
    setState,
    usaCadastroImobiliarioAtualizado,
    usaCadastroRuralAtualizado
  ) => {
    setState({ loading: true });
    const cadastroAtualizado =
      declaracao.tipoImovel === 'RURAL'
        ? usaCadastroRuralAtualizado
        : usaCadastroImobiliarioAtualizado;
    if (!cadastroAtualizado && declaracao.urlCertidaoIsencao) {
      const uid = DeclaracaoUtils.extractUid(declaracao.urlCertidaoIsencao);
      const isUidExpired = DeclaracaoUtils.isUidExpired(uid);
      if (DeclaracaoUtils.isUidExpired(uid)) {
        serviceFunction(declaracao.id)
          .then(response => {
            declaracao.urlCertidaoIsencao = response.data.url;
            window && window.open(response.data.url, '_blank');
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível imprimir a declaração de isenção' },
              error
            );
            setState({ loading: false });
          });
      }
      if (!isUidExpired) {
        window.open(declaracao.urlCertidaoIsencao, '_blank');
        setState({ loading: false });
      }
    }
    if (cadastroAtualizado || !declaracao.urlCertidaoIsencao) {
      serviceFunction(declaracao.id)
        .then(response => {
          declaracao.urlCertidaoIsencao = response.data.url;
          window && window.open(response.data.url, '_blank');
        })
        .catch(error => {
          Alert.error(
            { title: 'Não foi possível imprimir a declaração de isenção' },
            error
          );
          setState({ loading: false });
        });
    }
    setState({ loading: false });
  }
};

export default DeclaracaoUtils;
